(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('mhSelectPolicyCreationModal', selectPolicyCreationModal);
    function selectPolicyCreationModal () {
        return {
            restrict: 'E',
            scope: { choices: '<' },
            templateUrl: 'views/templates/chooseOrgModal.html',
            controller: function ($scope, $mdDialog, $state, toaster) {
                $scope.chosenOrg = {};
                $scope.createPolicy = () => {
                    if (!$scope.chosenOrg.id) {
                        toaster.pop('error', 'Create Policy', 'Please choose an organization');
                    } else {
                        $mdDialog.hide({ id: $scope.chosenOrg.id });
                    }
                };
                $scope.cancel = () => $mdDialog.hide({});
            }
        };
    }
})(window.angular, window._);
